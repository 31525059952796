import  React, { useState , useEffect } from 'react'

export const CurrentClock = () => {

    var [date,setDate] = useState(new Date());
    
    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }
    
    });

    return(
            <p>{date.toLocaleDateString()} {date.toLocaleTimeString()} CST</p>
    )
}

export default CurrentClock