import HomepageImage from './components/homepageImage'
import './App.css';
import styles from './App.css'
import CurrentClock from './components/CurrentClock';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <HomepageImage />
        <p style={{color: "red"}}>Website is under construction </p>
        <h6><CurrentClock/></h6>
        </header>
    </div>
  );
}

export default App;
